import React from "react"

function EnProceso(){

    return(
        <h1>Esta página está en proceso de construcción</h1>
    )

}

export default EnProceso