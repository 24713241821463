import React from "react";



function Acuerdo286(){

    return (           
         <section class= "quienes-somos-section">
    <div>
    <h1>
        En el Centro de Estudios de Bachillerato se apoya en el trámite del certificado por
        Acuerdo 286.
    </h1>
    <div>

        <h3>Pasos para realizar su solicitud</h3>
        <p>
            <ul>
                <li>Ingresar a <a href="https://dgb.sep.gob.mx/tramitesyservicios/">https://dgb.sep.gob.mx/tramitesyservicios/</a></li>
                <li>Revisar (según sea su trámite) los botones con las guías e información para el trámite que vaya a realizar
                    <ul>
                        <li>Emisión de <b>Certificado de Estudios</b> en el marco del Acuerdo Secretarial 286</li>
                        <li> Emisión de <b>Duplicado de Certificado de Estudios</b> en el marco del Acuerdo Secretarial 286</li>
                    </ul>
                </li>
                <li> Definido el trámite que va a realizar dé click ahora en el botón del Sistema Integral de la 
                    Dirección General de Bachillerato SiDGB disponible en: 
                    <a href="https://sidgb.sep.gob.mx/tramitesyservicios/#!/">https://sidgb.sep 
                    .gob.mx/tramitesyservicios/#!/</a></li>
                <li>Seleccione <b>Nueva Solicitud de Servicio</b></li>
                <li>Elegir el Tipo de Servicio (Emisión de Certificado de Estudios o Emisión de Duplicado de Certificado…)</li>
                <li>Llenar los datos de la Solicitud conforme a la guía respectiva</li>
                <li>Cargar los documentos requeridos en la forma que se solicita</li>
                <li>Generar la solicitud</li>
            </ul>

        </p>

        
        <h3>
            Seguimiento del trámite
        </h3>
        <p>
            <ul>
                <li>Ingrese nuevamente al módulo <a href="https://sidgb.sep.gob.mx/tramitesyservicios/#!/">https://sidgb.sep .gob.mx/tramitesyservicios/#!/</a></li>
                <li>Seleccione la pestaña <b>Seguimiento a Solicitudes</b>, donde podrá revisar el estado en que se 
                    encuentra su solicitud
                </li>
            </ul>
        
        </p>

        <h3>
            Como recoger su certificado
        </h3>

        <p>
            Una vez que se haya desahogado el procedimiento correspondiente, Control Escolar de la 
            Ciudad de México le enviará un correo indicándole la fecha a partir de la cual deberá acudir 
            personalmente a nuestro plantel para recibir su certificado.
        </p>
       
        <p>
            Considere que el horario de nuestro plantel para atender este trámite es de <b>10 de la 
            mañana a 2:45 de la tarde de Lunes a Viernes</b> en días hábiles y que deberá presentar 
            personalmente los siguientes documentos:
            <ul>
                <li> 
                   <b> Original y copia de la Solicitud en el formato que se genera</b> en el Módulo de 
                    Trámites y Servicios, con firma autógrafa en tinta azul
                </li>
                <li>
                    <b>Original del recibo bancario</b> por pago de derechos
                </li>
                <li>
                    <b>Original y copia de su identificación oficial vigente</b> (Credencial de Elector, 
                    Pasaporte o Cédula Profesional)
                </li>
            </ul>
        </p>
        <h3>
        Lista de certificados que ya están en Delicias
        </h3>
        <p>
        Si usted ya realizó su trámite y quiere conocer si su certificado ya se encuentra en nuestro plantel,
         puede realizar su consulta dando click en el siguiente 
         <a href="https://docs.google.com/spreadsheets/d/1fzWiuE9uAN8iu3ZEwiNsFaoW2G8smiSsyuLJXCbyCzc/edit?usp=sharing"> Link</a>
        </p>
        <p>
            Cualquier duda por favor enviar un mensaje de Whatsapp al 639 119 85 68
        </p>
        <p>
            Centro de Estudios de Bachillerato 6/4 Cd. Delicias. 
        </p>
        <p>
            Dirección: Privada de Guadalupe 106, Fracc. Flores del Tepeyac, CP 33085, Cd. Delicias, Chih.
        </p>
    </div>
    </div>
</section>)

}

export default Acuerdo286;