import React, { } from 'react';
import "bootstrap/dist/css/bootstrap.min.css"

import './res/StylesSheets/Fichaje.css';

import carousel1 from "./res/images/Imagen 11.jpg";
import carousel2 from "./res/images/Imagen 22.jpg";
import carousel3 from "./res/images/Imagen 23.jpg";
import carousel4 from "./res/images/Imagen 24.jpg";
import carousel5 from "./res/images/Imagen 25.jpg";
import carousel6 from "./res/images/Imagen 26.jpg";

import Carousel from  'react-bootstrap/Carousel';


function Fichaje(){

    return(
        <div className="App">
 
            <section className= "quienes-somos-section">
                <div>
                <h1>
                    El Pedagógico inicia su entrega de fichas 2022.
                </h1>
                <div>
                    <p>
                        El Centro de Estudios de Bachillerato 6/4 Cd. Delicias, mejor conocido como
                        el Pedagógico, inicia su entrega de fichas para el examen de admisión 2022.
                    </p>
                    <p>
                        Este año la solicitud de fichas se realizará a partir del <b>4 de abril y hasta el 15
                        de mayo de 2022</b> 
                    </p>
                    <p>
                        La solicitud se hará en línea a través del siguiente 
                        <a href = "https://sie.chihuahua.gob.mx/publico/mediasuperior/ "> link </a>
                        y los aspirantes que
                        no cuenten con Internet podrán acudir al plantel a llenar su solicitud y sacar
                        su ficha.
                    </p>
                    <p>
                        La mecánica es la siguiente: los aspirantes realizan su registro en línea y al día
                        siguiente acuden al plantel a entregar una copia de su CURP y una copia de
                        un comprobante de domicilio, para luego recibir su guía impresa y su pase
                        para el examen de admisión. El costo de la ficha es de 120 pesos.
                    </p>
                    <p>
                        <b>El examen de admisión se llevará a cabo el 24 de junio </b>
                        a partir de las <b>7:30</b> de 
                        la mañana y la publicación de <b>resultados el 18 de julio</b>.
                    </p>
                    <p>
                        El Centro de Estudios de Bachillerato 6/4 es la escuela con mayor demanda y
                        crecimiento en los últimos años, lo cual la ha convertido en la mejor opción
                        educativa no solo en Delicias, sino en toda la región Centro Sur del Estado.
                    </p>
                    <p>
                        En el Pedagógico contamos con cerca de 1500 alumnos convencidos de la
                        Calidad de nuestra Escuela, por lo cual te invitamos a sumarte a nuestro
                        plantel y estudiar el Bachillerato General con el cual podrás ingresar a
                        cualquier Universidad, Tecnológico o Normal,

                    </p>
                    <p>Contamos con las siguientes fortalezas</p>
                    <div className="listaaa">
                            <li>Excelentes resultados académicos</li>
                            <li>Alumnos con interés por estudiar</li>
                            <li>Docentes calificados y certificados</li>
                            <li>La aportación de padres es la más baja de la ciudad</li>
                            <li>Becas Benito Juárez para sus alumnos</li>
                            <li>Agradable y sano ambiente escolar</li>
                            <li>Instalaciones seguras y equipadas, además transporte hacia el plantel</li>
                            <li>Convivencia y múltiples actividades sociales, culturales y equipos
                                deportivos
                            </li>
                            <li>Capacitaciones: Contabilidad, Pedagogía, Higiene y Salud, Informática,Diseño.</li>
                            <li>El más alto nivel en el padrón de calidad entre las escuelas de la región</li>

                    </div>
                        <p>
                            Hoy en día, el sistema educativo tiene los espacios suficientes para que
                            ningún estudiante de la región se quede sin estudiar su preparatoria. Te
                            invitamos a ser parte de nuestra comunidad educativa.
                        </p>
                        <p>
                            Cualquier duda o si desea que le apoyemos en el trámite de su ficha, por
                            favor envíe un whatsapp al 6391198568 o comuníquese porl teléfono al
                            6394729828.
                        </p>
                        <h3>¡Orgullosamente CEB 6/4!</h3>     
                </div>
                </div>
            </section>

            <section className= "gallery-section">
                <div>
                <div>
                    <h2>Galería</h2>

                    <Carousel className ="galeria">
                        <Carousel.Item>
                            <div>
                                <img className="d-block w-75" src={carousel1} alt="Second slide" />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div>
                                <img className="d-block w-75" src={carousel2} alt="Second slide" />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div>
                                <img className="d-block w-75" src={carousel3} alt="Second slide" />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div>
                                <img className="d-block w-75" src={carousel4} alt="Second slide" />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div>
                                <img className="d-block w-75" src={carousel5} alt="Second slide" />
                            </div>
                        </Carousel.Item>
                        <Carousel.Item>
                            <div>
                                <img className="d-block w-75" src={carousel6} alt="Second slide" />
                            </div>
                        </Carousel.Item>
                    </Carousel>

                </div>
                </div>
            </section>
        </div>

    );
}

export default Fichaje;