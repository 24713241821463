import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import carouselImg1 from './images/bannerNuevoIng.jpg';
import carouselImg3 from './images/imagen-prov.jpg';
import NewBan from "./New"

import './NoticeBoard.css';
import{ Link } from 'react-router-dom';



function NoticeBoard() {

    let convocatoriaMsg = 
        <div>
            <div className='visible-for-mov'>
                Únete a la preparatoria de mayor demanda y crecimiento en los últimos años 
                en todo Delicias y la región: El Pedagógico
            </div>
            <div className='visible-for-desk'>
                El Centro de Estudios de Bachillerato 6/4 invita a todos los alumnos de 3ero de Secundaria
                a continuar sus estudios en la preparatoria de mayor demanda y crecimiento en los últimos años 
                en todo Delicias y la región: El Pedagógico
            </div>
            <ul className='lista-convo visible-for-desk'>
                <b>
                <li>Entrega de fichas: 4 de abril al 15 de mayo</li>
                <li>Examen de admisión: 24 de junio</li>
                <li>Resultados: 18 de julio</li>
                </b>
            </ul>
            <div className='visible-for-mov'>
                Cualquier duda, envía un whatsapp al 639 119 85 68
            </div>
            <div className='visible-for-desk'>
                Si quieres mantenerte informado, recibir la guía, o que te apoyemos en cualquier duda, envía un whatsapp al 639 119 85 68<br /><br />
                Orgullosamente CEB 6/4<br />
                Siempre Pedagógico
            </div>

            
        </div>


        let fichasTxt = 
            <div>
                <p>
                    El Centro de Estudios de Bachillerato 6/4 “Cd. Delicias” le da la más cordial bienvenida a los alumnos y alumnas
                    aceptados al primer Semestre para el Ciclo Escolar 2022-2023 
                </p>
                <p className='visible-for-desk'>
                    Complete la preinscripción en línea antes del 23 de Julio de 2022 , para
                    después pasar a la etapa de inscripción, donde se solicitará la entrega de la 
                    documentación a la fecha y hora detallada en el link de abajo.
                </p>

            </div>

            
        let fichaButtons = [
            {
                url:"https://www.ceb64.com/aceptados22.pdf",
                txt:"Resultados del exámen y Detalles de la inscripción"
            }
        ]

    
    return (
    <section className="noticeBoard_section gen-sec-grid"> 
        <Carousel>
            <Carousel.Item>
                <NewBan 
                    titulo = "Resultados del examen de admisión y proceso de inscripción para primer semestre 2022-2023"
                    texto = {fichasTxt}
                    img = {carouselImg3}
                    buttons = {fichaButtons}
                />
            </Carousel.Item>
        </Carousel>
    </section>
    
  );
}

export default NoticeBoard;
